
import CryptoJS from "crypto-es";
var __encode = 'jsjiami.com', _a = {}, _0xb483 = ["\x5F\x64\x65\x63\x6F\x64\x65", "\x68\x74\x74\x70\x3A\x2F\x2F\x77\x77\x77\x2E\x73\x6F\x6A\x73\x6F\x6E\x2E\x63\x6F\x6D\x2F\x6A\x61\x76\x61\x73\x63\x72\x69\x70\x74\x6F\x62\x66\x75\x73\x63\x61\x74\x6F\x72\x2E\x68\x74\x6D\x6C"]; (function (_0xd642x1) { _0xd642x1[_0xb483[0]] = _0xb483[1] })(_a); var __Oxa1800 = ["\x70\x74\x63\x71\x6C\x6D\x77\x68\x63\x6D\x79\x78\x67\x73\x70\x74", "\x75\x6E\x64\x65\x66\x69\x6E\x65\x64", "\x6C\x6F\x67", "\u5220\u9664", "\u7248\u672C\u53F7\uFF0C\x6A\x73\u4F1A\u5B9A", "\u671F\u5F39\u7A97\uFF0C", "\u8FD8\u8BF7\u652F\u6301\u6211\u4EEC\u7684\u5DE5\u4F5C", "\x6A\x73\x6A\x69\x61", "\x6D\x69\x2E\x63\x6F\x6D"]; var aseKey = __Oxa1800[0x0]; (function (_0x52ccx2, _0x52ccx3, _0x52ccx4, _0x52ccx5, _0x52ccx6, _0x52ccx7) { _0x52ccx7 = __Oxa1800[0x1]; _0x52ccx5 = function (_0x52ccx8) { if (typeof alert !== _0x52ccx7) { alert(_0x52ccx8) } if (typeof console !== _0x52ccx7) { console[__Oxa1800[0x2]](_0x52ccx8) } }; _0x52ccx4 = function (_0x52ccx9, _0x52ccx2) { return _0x52ccx9 + _0x52ccx2 }; _0x52ccx6 = _0x52ccx4(__Oxa1800[0x3], _0x52ccx4(_0x52ccx4(__Oxa1800[0x4], __Oxa1800[0x5]), __Oxa1800[0x6])); try { _0x52ccx2 = __encode; if (!(typeof _0x52ccx2 !== _0x52ccx7 && _0x52ccx2 === _0x52ccx4(__Oxa1800[0x7], __Oxa1800[0x8]))) { _0x52ccx5(_0x52ccx6) } } catch (e) { _0x52ccx5(_0x52ccx6) } })({})
var key = CryptoJS.enc.Utf8.parse(aseKey); //将秘钥转换成Utf8字节数组
export default {
	encrypt(obj) {
		var e = CryptoJS.AES.encrypt(JSON.stringify(obj), key, {
			iv: CryptoJS.enc.Utf8.parse(aseKey.substr(0, 16)),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return e;
	},
	decrypt(obj) {
		var e = CryptoJS.AES.decrypt(obj, key, {
			iv: CryptoJS.enc.Utf8.parse(aseKey.substr(0, 16)),
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return e.toString(CryptoJS.enc.Utf8);
	}
};