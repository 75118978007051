export default [{
    "name": "互联网",
    "id": "001",
},
{
    "name": "金融",
    "id": "002",
},
{
    "name": "机械机电",
    "id": "003",
},
{
    "name": "工业制品",
    "id": "004",
},
{
    "name": "电子、电工",
    "id": "005",
},
{
    "name": "礼品、工艺品、饰品",
    "id": "006",
},
{
    "name": "石油化工",
    "id": "007",
},

{
    "name": "家居用品",
    "id": "008",
},
{
    "name": "建筑、建材",
    "id": "009",
},
{
    "name": "服装、鞋帽",
    "id": "010",
},
{
    "name": "纺织、皮革",
    "id": "011",
},
{
    "name": "电脑与软件",
    "id": "012",
},
{
    "name": "办公家具",
    "id": "013",
}, {
    "name": "商务服务",
    "id": "014",
}, {
    "name": "轻工食品",
    "id": "015",
}, {
    "name": "包装用品",
    "id": "016",
}, {
    "name": "冶金矿产",
    "id": "017",
}, {
    "name": "农林牧渔",
    "id": "018",
}, {
    "name": "运动、休闲",
    "id": "019",
}, {
    "name": "汽摩及配件",
    "id": "020",
}, {
    "name": "家用电器",
    "id": "021",
}, {
    "name": "医药、保养",
    "id": "022",
}, {
    "name": "通讯产品",
    "id": "023",
}, {
    "name": "安全、防护",
    "id": "024",
}, {
    "name": "印刷、出版",
    "id": "025",
}, {
    "name": "玩具",
    "id": "026",
}, {
    "name": "能源",
    "id": "027",
}, {
    "name": "各类加工",
    "id": "028",
}, {
    "name": "环保绿化",
    "id": "029",
}, {
    "name": "代理",
    "id": "030",
}, {
    "name": "纸业",
    "id": "031",
}, {
    "name": "种植、养殖",
    "id": "032",
}, {
    "name": "金融投资",
    "id": "033",
}, {
    "name": "房地产",
    "id": "034",
}, {
    "name": "机构组织",
    "id": "035",
}, {
    "name": "水利水电",
    "id": "036",
}, {
    "name": "节能环保",
    "id": "037",
}, {
    "name": "建筑建材",
    "id": "038",
}, {
    "name": "基础设施",
    "id": "039",
}, {
    "name": "家用日化",
    "id": "040",
}, {
    "name": "烟酒茶叶",
    "id": "041",
}, {
    "name": "交通运输",
    "id": "042",
}, {
    "name": "仓储物流",
    "id": "043",
}, {
    "name": "汽车汽配",
    "id": "044",
}, {
    "name": "旅游酒店",
    "id": "045",
}, {
    "name": "餐饮娱乐",
    "id": "046",
}, {
    "name": "教育培训",
    "id": "047",
}, {
    "name": "文化传媒广告",
    "id": "048",
}, {
    "name": "批发零售",
    "id": "049",
}, {
    "name": "家居日用",
    "id": "050",
}, {
    "name": "海洋开发",
    "id": "051",
}, {
    "name": "商务贸易",
    "id": "052",
}, {
    "name": "家政服务",
    "id": "053",
}, {
    "name": "园林园艺",
    "id": "054",
}, {
    "name": "收藏品",
    "id": "055",
}, {
    "name": "体育用品",
    "id": "056",
}, {
    "name": "信息产业",
    "id": "057",
}, {
    "name": "专业服务",
    "id": "058",
}, {
    "name": "健康养生",
    "id": "059",
}, {
    "name": "母婴用品",
    "id": "60",
}, {
    "name": "旅游行业 ",
    "id": "061",
},
{
    "name": "其他 ",
    "id": "062",
},
]